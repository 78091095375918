.filter-btn {
    width: fit-content;
    height: 45px;
    font-size: 18px;
    border-radius: 10px;
    background: #ffffff;
    border: 1px solid #00a290;
    color: #00a290;
    cursor: pointer;
}
.dropdown-container {
    min-width: 250px;
    margin: 0 8px 0 8px;
}
