.back-btn {
  background: #FFFFFF ;
  border: 1px solid #00A290;
  border-radius: 10px;
  width: 170px;
  text-align: center;
  font-size: 1.2rem;
  font-weight: 600;
  letter-spacing: 0.72px;
  color: #00A290;
  height: 55px;
  line-height: 3;
  cursor: pointer;
}