.input-group-custom{
  .prepend-text{
    border: 1px solid #00A290;
    border-radius: 10px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    background: #FFFFFF;
    text-align: left;
    letter-spacing: 0.64px;
    color: #00A290;
    opacity: 1;
    font-size: 1rem;
    font-family: "Urbanist", sans-serif;
    width: 25%;
  }
  .main-con-input{
      width: 75%;
  }
  .input-group-shadow{
    input{
      border-top-left-radius: 0px;
      border-bottom-left-radius: 0px;
      box-shadow: none;
    }
  }

}