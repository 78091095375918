.profile-menu-con{
  .menu-icon-con{
    background: #00A290;
    padding: 17px 10px;
    color: white;
    border-radius: 10px;
    i{
      font-size: 2.5rem;
    }
  }

  .dropdown-menu-end{
    box-shadow: 0px 12px 18px #00000014;
    border: 1px solid #00A290;
    border-radius: 20px;
    opacity: 1;
    background-color: #fff;
    .dropdown-item{
      text-align: left;
      letter-spacing: 0.32px;
      color: #000000;
      opacity: 1;
      font-family: "Urbanist", sans-serif;
      font-weight: 600;
      font-size: 1rem;
      padding: 0.75rem 2rem;
      &:hover{
        border-radius: 10px;
      }
    }
  }
}